import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Div from './../components/Div'
import Img404 from '../images/404.svg'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Div
      maxWidth={['90%', 390, 450, 450]}
      style={{
        margin: '0 auto'
      }}
    >
      <img
        src={Img404}
      />
    </Div>
  </Layout>
)

export default NotFoundPage
